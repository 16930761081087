<template>
    <footer>
        <div class="rows">
            <div class="footer_logo">
                <img src="../../public/images/main/footer_logo.png" />
            </div>
            <address>
                <div>
                    <span>(주)비바소프트</span>
                    <span>대표자명 : 김순기</span>
                    <span>주소 : 서울특별시 마포구 양화로 161, 케이스퀘어 615호</span>
                </div>
                <div>
                    <span>E-mail : manager@vivasoft.co.kr</span>
                    <span>TEL. 02-2038-2690</span>
                </div>                
                <div class="copy">
                    <p>Copyrightⓒ (주)비바소프트 All Rights Reserved.</p>
                </div>
            </address>
        </div>
    </footer>
</template>

<script>
export default {
    name:'Footer',
}
</script>

<style>

</style>