<template>
    <main id="contents">
        <section id="visual">
            <img src="../../public/images/main/visual.png" title="중소기업에 특화된  구독형  온라인 서비스를 지금 만나보세요" />
            <div class="text">
                <h2>중소기업에 특화된 <span>구독형</span> 온라인 서비스를 지금 만나보세요</h2>
                <p>중소기업을 위한 다양한 서비스를 제공합니다.</p>
            </div>
        </section>

        <section id="about">
            <div class="rows">
                <h3 class="section_tit"  data-aos="fade-right"><span>A</span>BOUT</h3>
                <div class="con" data-aos="fade-up">
                    <div class="img">
                        <img src="../../public/images/main/about.png" title="about 이미지" />
                    </div>
                    <div class="about_text">
                        <h4>중소기업을 위한&nbsp<strong>구독형 온라인 서비스를 제공</strong>합니다.</h4>
                        <p><strong>㈜비바소프트는 중소기업이 필요로 하는 다양한 업무분야의 솔루션을 구독형 온라인 서비스로 제공하고자 설립한 회사</strong>입니다.</p>
                        <p>첫번째 분야로 2023년 1월 27일 전면 시행되는 <strong>중대재해처벌법에 대응하기 위한 구독형 서비스를 개발하고 서비스</strong>를 시작합니다.</p>
                        <p>향후, 중소기업이 대응하기 어려운 분야를 지속적으로 발굴, 개발하여 지원분야를 확대하고자 합니다.</p>
                    </div>
                </div>
            </div>
        </section>

        <section id="business">
            <div class="rows">
                <h3 class="section_tit" data-aos="fade-right"><span>B</span>USINESS</h3>
                <div class="business_wrap">
                    <div >
                        <div class="img" data-aos="fade-up">
                            <img src="../../public/images/main/business_01.png" />
                        </div>
                        <div class="text right"  data-aos="fade-right">
                            <div class="tit" style="cursor:pointer" @click="onlink('https://www.nurisafe.co.kr')">
                                <h4>A. 누리세이프</h4>
                                <span class="link"  > www.nurisafe.co.kr</span>

                            </div>
                            <div class="con">
                                <div class="group">
                                    <p class="p_txt">누리세이프는 산업안전보건법 및 중대재해처벌법 기준에 따른 중소기업의 활동을 지원하기 위한 구독형 온라인 서비스입니다.</p>
                                </div>
                                <div class="group">
                                    <span class="s_tit">주요특징</span>
                                    <ul class="list_01">
                                        <li>산업안전보건관리체계 지원을 위한 온라인 구독형 서비스</li>
                                        <li>컨설팅 프로세스 기반의 계획 수립 및 지속적인 활동 모니터링 기능</li>
                                        <li>샘플 선택을 통한 손쉬운 안전보건관리체계 운영규정 수립 지원 </li>
                                        <li>위험기계기구 등록에 따른 안전점검 체크리스트 자동 제공</li>
                                        <li>모바일 Push 알람을 통한 일·주·월 업무 알림 제공</li>
                                        <li>절차서, 규정정보, 샘플양식 등 다양한 문서정보 제공</li>
                                        <li>사업장 유형(본사, 지점, 현장사업장)에 따른 통합 안전보건 활동 모니터링</li>
                                        <li>법적대응을 위한 규정, 계획, 실시결과물의 일괄 출력 제공</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="right">
                        <div class="img" data-aos="fade-up">
                            <img src="../../public/images/main/business_02.png" />
                        </div>
                        <div class="text left" data-aos="fade-left">
                            <div class="tit">
                                <h4>B. 에듀테크 서비스 솔루션</h4>
                            </div>
                            <div class="con">
                                <div class="group">
                                    <p class="p_txt">평등한 교육환경 제공을 목표로 LMS(Learning Management System), LRS(Learning Record Store), Learning Analytics 등 에듀테크와 관련된 서비스를 개발하고 제공합니다.</p>
                                    <p class="p_txt">문제은행기반 문제풀이서비스는 자격증 취득을 위한 출제경향 분석 및 개인 맞춤형 문제추천 서비스로 시험을 앞두고 시간이 부족한 수험생들에게 최적의 방법을 제공하고자 하는 온라인 서비스입니다.</p>
                                </div>
                                <div class="group">
                                    <span class="s_tit">주요특징</span>
                                    <ul class="list_01">
                                        <li>기출문제 분석을 통한 출제 예측 문제 제공</li>
                                        <li>오답노트 기반의 취약점 분석 및 개인 맞춤형 문제/콘텐츠 제공</li>
                                        <li>유사문제 생성을 통한 취약점 반복 학습 제공</li>
                                    </ul>
                                </div>
                                <!-- div class="group">
                                    <span class="s_tit">기타 등등</span>
                                    <p class="p_txt">신규 비즈니스 분야가 늘어나면 추가하기 쉬운 구조로 디자인 요청</p>
                                </div -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="contact">
            <div class="rows">
                <h3 class="section_tit" data-aos="fade-right"><span>C</span>ONTACT</h3>
                <div class="map_wrap" data-aos="fade-up">
                    <div id="daumRoughmapContainer1706248181354" class="root_daum_roughmap root_daum_roughmap_landing"></div>
                </div>
                <ul class="contact_li">
                    <li>
                        <span class="tit">ADDRESS</span>
                        <p>서울특별시 마포구 양화로 161, 케이스퀘어 615호</p>
                    </li>

                    <li>
                        <span class="tit">TEL</span>
                        <p><a href='tel:02-2038-2690'>02-2038-2690</a></p>
                    </li>

                    <li>
                        <span class="tit">E-MAIL</span>
                        <p><a href="mailto:﻿manager@vivasoft.co.kr">manager@vivasoft.co.kr</a></p>
                    </li>
                </ul>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    name:'Main',
    mounted() {
        new daum.roughmap.Lander({
		"timestamp" : "1706248181354",
		"key" : "2hupm",
	    }).render();
    },
    methods: {
      onlink(url) {
        // 로딩이 완료되면 로딩 상태를 false로 변경
        window.open(url, '_blank');
      },
    },
}
</script>

<style>
.contact_li li a{
	word-break:break-all;
}
</style>