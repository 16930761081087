<template>
    <div id="skip">
	    <a href="#gnb">주메뉴 바로가기</a>
	    <a href="#content">본문 바로가기</a>
    </div>
    <header>
        <div class="rows">
            <h1 class="logo"><img src="../../public/images/logo.png" ></h1>
            <nav id="gnb">
                <a href="#top">HOME</a>
                <a href="#about">ABOUT</a>
                <a href="#business">BUSINESS</a>
                <a href="#contact">CONTACT</a>
            </nav>
        </div>
        
        <!-- 모바일 메뉴 -->
        <a href="javascript:void(0)" class="nav_toggle" @click="toggle" :class="{active : nav == true}" title="모바일 메뉴">
			<span class="line"></span>
			<span class="line"></span>
			<span class="line"></span>
		</a>

        <div class="mobile" :class="{active : nav == true}" >
            <ul>
                <li @click="nav=false"><a href="#top">HOME</a></li>
                <li @click="nav=false"><a href="#about">ABOUT</a></li>
                <li @click="nav=false"><a href="#business">BUSINESS</a></li>
                <li @click="nav=false"><a href="#contact">CONTACT</a></li>
            </ul>
        </div>
    </header>
</template>

<script>
export default {
    name:'Header',

    data() {
        return {
            nav: false,
        };
    },
    methods:{
        toggle:function(){
            this.nav = !this.nav;
        },
    }
}
</script>

<style>

</style>