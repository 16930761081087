<template>
    <Header/>
    <Main/>
    <Footer/>
</template>

<script>

import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Main from './components/Main.vue';

export default {
  name: 'App',
  data(){
   
  },
  components: {
    Header : Header, 
    Footer : Footer,
    Main : Main
  }
}
</script>
